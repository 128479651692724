<template>
  <div>
    <b-row class="" align-h="center">
      <b-col cols="12">
        <Card class="py-4 px-4 login-card" v-if="!loading">
          <template v-slot:content>
            <!--begin::Signin-->
            <div class='login-form login-signin'>
              <div class='text-center' v-if="success">
                <b-iconstack font-scale="5" class="mb-5" >
                  <b-icon stacked icon="shield-fill-check" variant="success" scale="1"></b-icon>
                </b-iconstack>

                <h4>{{ $t('GENERAL.congratulations') }}</h4>

                <p class="paragraph">{{ $t('AUTH.confirm_account_description') }}</p>

                <router-link :to="{ name: 'login' }" class="btn btn-primary">{{ $t('GENERAL.back_to_home') }}</router-link>
              </div>

              <div class='text-center' v-if="error">
                <b-iconstack font-scale="5" class="mb-5" >
                  <b-icon stacked icon="shield-fill-x" variant="danger" scale="1"></b-icon>
                </b-iconstack>

                <h4>{{ $t('GENERAL.title_error') }}</h4>

                <p v-if="isTokenMissing" class="paragraph">{{ $t('AUTH.ERRORS.token_missing') }}</p>
                <p v-if="tokenAlreadyUsed" class="paragraph">{{ $t('AUTH.ERRORS.token_already_used') }}</p>

                <router-link :to="{ name: 'login' }" class="btn btn-primary mt-5">{{ $t('GENERAL.back_to_home') }}</router-link>
              </div>
            </div>
            <!--end::Signin-->
          </template>
        </Card>
      </b-col>
    </b-row>
    <loading :loading="loading" />
  </div>
</template>

<script>
import ToastsMixin from '@/services/mixins/toasts.mixins'

import ApiService from '@/services/api.service'
import URLS from '@/config/urls'

export default {
  name: 'login',
  mixins: [ToastsMixin],
  data () {
    return {
      loading: false,
      error: '',
      success: false
    }
  },
  methods: {
    validateToken (token) {
      this.loading = true
      return ApiService.post(URLS.API.AUTH.VALIDATE_CONFIRMATION_TOKEN, { token: token })
        .then(_ => { this.success = true })
        .catch(_ => {
          // TODO Get error messages from the backend
          this.error = 'token_already_used'
          this.showErrorToast(this.$t('AUTH.forgot_password_error'))
        })
        .then(_ => { this.loading = false })
    }
  },
  computed: {
    isTokenMissing () { return this.error === 'missing_token' },
    tokenAlreadyUsed () { return this.error === 'token_already_used' }
  },
  mounted () {
    var token = this.$route.query.token

    if (typeof token !== 'undefined' && token !== null && token) {
      this.validateToken(token)
    } else {
      this.error = 'missing_token'
    }
  }
}
</script>

<style lang='scss' scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important
}

.resend-link {
  margin-top: 1rem;
  display: block;
  color: $primary-dark;

  cursor: pointer;
}
</style>
